// CLIENT Configuration
// This section contains properties related to the Kafka client configuration.
// ANY PROPERTY NOT EXPLICITLY DEFINED HERE WILL BE THE DEFAULT CONFLUENT KAFKA JAVASCRIPT CONFIGURATION
export const CONNECTION_TIMEOUT = 15000;
export const REQUEST_TIMEOUT = 60000;
export const AUTHENTICATION_TIMEOUT = 10000;
export const REAUTHENTICATION_THRESHOLD = 10000;
export const ENFORCE_REQUEST_TIMEOUT = false;

export const OPENQ_KAFKA_CLIENT_CONFIGURATION = {
  connectionTimeout: CONNECTION_TIMEOUT,
  requestTimeout: REQUEST_TIMEOUT,
  authenticationTimeout: AUTHENTICATION_TIMEOUT,
  enforceRequestTimeout: ENFORCE_REQUEST_TIMEOUT,
  logLevel: 4,
};

// CONSUMER Configuration
// This section contains properties related to the Kafka consumer configuration.
// ANY PROPERTY NOT EXPLICITLY DEFINED HERE WILL BE THE DEFAULT CONFLUENT KAFKA JAVASCRIPT CONFIGURATION
export const PARTITIONS_CONSUMED_CONCURRENTLY = 1;

export const CONSUMER_SESSION_TIMEOUT = 1500000;
export const MAX_POLL_INTERVAL = 2000000;
export const HEARTBEAT_INTERVAL = 3000;
export const REBALANCE_TIMEOUT = 240000;
export const INITIAL_RETRY_TIME = 100;
export const RETRIES = 20;

export const OPENQ_CONSUMER_CONFIGURATION = {
  sessionTimeout: CONSUMER_SESSION_TIMEOUT,
  autoCommit: false,
  logLevel: 1,
  retry: {
    initialRetryTime: INITIAL_RETRY_TIME,
    retries: RETRIES,
  },
};
